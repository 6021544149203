.alert{
    padding: 10px 15px;
    font-size: 14px;

    .container &{
        border-radius: 4px;

    }
    .navbar &{
        border-radius: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 85px;
        width: 100%;
        z-index: 3;
    }
    .navbar:not(.navbar-transparent) &{
        top: 70px;
    }

    span[data-notify="icon"]{
        font-size: 30px;
        display: block;
        left: 15px;
        position: absolute;
        top: 50%;
        margin-top: -15px;
    }

    button.close{
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -13px;
        z-index: 1033;
        background-color: #FFFFFF;
        display: block;
        border-radius: 50%;
        opacity: .4;
        line-height: 20px;
        font-size: 12px;
        width: 25px;
        height: 25px;
        outline: 0 !important;
        text-align: center;
        padding: 3px;
        font-weight: 300;

        &:hover{
            opacity: .55;
        }
    }

    .close ~ span{
        display: block;
        max-width: 89%;
    }

    &[data-notify="container"]{
        padding: 10px 10px 10px 20px;
        border-radius: $border-radius-base;
    }

    &.alert-with-icon{
        padding-left: 65px;
    }
    a, a:hover, a:focus{
        text-decoration: underline;
        color: inherit;
    }

    &.ribbon{
        background-color: rgb(212, 237, 218);
        position: absolute !important;
        top: 4.5em;
        right: -1px;
        font-weight: bold;
        border-radius: 0 !important;
        border: none;
        height: 3em;
        z-index: 5;
        font-size: 1em;
        padding: 1em;
        line-height: 1em;
    }
    &.ribbon::before{
        content: "";
        border: 1.5em solid rgb(212, 237, 218);
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        border-left: 10px solid transparent;
        border-right: none;
    }
    &.ribbon::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: -9px;
        right: 0;
        top: 0;
        box-shadow: 0px 2px;
    }
}
