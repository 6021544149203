$general-bg:                  #2d3044;

/* Musician colors */

$musician-bg-left:            #6c0542;
$musician-bg-right:           #090a0e;
$musician-active:             #5D5060;
$musician-sidebar-left:       #3e1c33;
$musician-sidebar-right:      #361e2e;
$musician-bright-color:       #DC007D;


/* Engineer colors */

$engineer-bg-left:            #08337d;
$engineer-bg-right:           #090a0e;
$engineer-active:             #505a60;
$engineer-sidebar-left:       #1c303e;
$engineer-sidebar-right:      #1e2636;
$engineer-bright-color:       #00d7c6;
