.btn{
    border-width: $border-thick;
    background-color: $transparent-bg;
    font-weight: $font-weight-normal;

    @include opacity(.8);
    padding: $padding-base-vertical $padding-base-horizontal;

    @include btn-styles($default-color, $default-states-color);

    &:hover,
    &:focus{
        @include opacity(1);
        outline: 0 !important;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
         @include box-shadow(none);
         outline: 0 !important;
    }

}

// Apply the mixin to the buttons
//.btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary { @include btn-styles($primary-color, $primary-states-color); }
.btn-success { @include btn-styles($success-color, $success-states-color); }
.btn-info    { @include btn-styles($info-color, $info-states-color); }
.btn-warning { @include btn-styles($warning-color, $warning-states-color); }
.btn-danger  { @include btn-styles($danger-color, $danger-states-color); }
.btn-neutral {
    @include btn-styles($white-color, $white-color);

    &:active,
    &.active,
    .open > &.dropdown-toggle{
         background-color: $white-color;
         color: $default-color;
    }

    &.btn-fill,
    &.btn-fill:hover,
    &.btn-fill:focus{
        color: $default-color;
    }

    &.btn-simple:active,
    &.btn-simple.active{
        background-color: transparent;
    }
}

.btn{
  font-size: 12px;
  min-height: 40px;
  font-weight: 600;
  padding: 0px 2em;
    text-transform: uppercase;
     &:disabled,
     &[disabled],
     &.disabled{
        @include opacity(.5);
    }
}
.btn-social{
  padding: 1em 2em;
}

.btn-round{
    border-width: $border-thin;
    border-radius: $btn-round-radius !important;

}
.btn-simple{
    border: $none;
    font-size: $font-size-medium;
    padding: $padding-base-vertical $padding-base-horizontal;

}
.btn-lg{
   @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
   font-weight: $font-weight-normal;
}
.btn-sm{
    @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
.btn-xs {
    @include btn-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $border-radius-small);
}
.btn-wd {
    min-width: 140px;
}

.btn-group.select{
    width: 100%;
}
.btn-group.select .btn{
    text-align: left;
}
.btn-group.select .caret{
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 8px;
}

.btn-icon{
  font-size: 24px;
  padding: 0px;
  border: none;
}

/* REFERENCES + SPOTIFY */


.spotifySearch{
  border: 1px solid $light-gray;
  padding: 1em;
  margin-bottom: 1em;
}

.spotifyElement{
  display: flex;
  padding: 5px 0px;
  align-items: center;
}

.besideSpotify{
  padding: 0px;
  height: 60px;
  width:60px;

  margin-left: 10px;
}


/* TIER CARDS */

.tierCard{
  margin-bottom: 2em;
  opacity: 1;
  background-color: $white-bg;
  border: 2px solid $light-gray;
  color: $black-color;
  &:active, &.active{
    color: $black-color;
    background-color: $white-bg;
    -webkit-box-shadow: 0px 5px 10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 10px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 10px rgba(0,0,0,0.75);
    border-color: $musician-bright-color;
  }
  &:disabled, &.disabled{
    background-color: $light-gray;
  }
  .price{
    font-size: 2em;
  }
  h3{
    position: relative;
    color: $musician-bright-color;
  }
  h3 strong{
    color: $black-color;
  }
  ul{
    padding-left: 10px;
    font-weight: normal;
  }
}

.reviewAccept{
  margin-bottom: 1em;

}


/* SELECT GRID HACKY ANIMATIONS! */

@keyframes focusAnimation {
  from {width: 100%; left:0%}
  to {width: 455%; left: -315%}
}
@keyframes blurAnimation {
  from {width: 455%; left: -315%}
  to {width: 100%; left:0%}
}

@media (min-width: 576px) {
  .animateFocus{
    width: 455%;
    left: -315%;
    z-index: 5;
    animation-name: focusAnimation;
    animation-duration: 0.5s;
  }


  .animateBlur{
    width: 100%;
    left: 0%;
    z-index: 1;
    animation-name: blurAnimation;
    animation-duration: 0.5s;
  }
}
