.form-control::-moz-placeholder{
   @include placeholder($medium-gray,1);
}
.form-control:-moz-placeholder{
   @include placeholder($medium-gray,1);
}
.form-control::-webkit-input-placeholder{
   @include placeholder($medium-gray,1);
}
.form-control:-ms-input-placeholder{
   @include placeholder($medium-gray,1);
}

.form-control {
    background-color: $white-bg;
    border: 1px solid $light-gray;
    border-radius: $border-radius-base;
    color: #565656;
    @include input-size($padding-base-vertical, $padding-base-horizontal - 4, $height-base);
    @include box-shadow(none);

    &:focus{
           background-color: $white-bg;
           border: 1px solid $medium-dark-gray;
           @include box-shadow(none);
           outline: 0 !important;
           color: #333333;
    }

    .has-success &,
    .has-error &,
    .has-success &:focus,
    .has-error &:focus{
        border-color: $light-gray;
        @include box-shadow(none);
    }

    .has-success &{
        color: $success-color;
    }
    .has-success &:focus{
        border-color: $success-color;
    }
    .has-error &{
        color: $danger-color;
    }
    .has-error &:focus{
        border-color: $danger-color;
    }

    & + .form-control-feedback{
        border-radius: $border-radius-large;
        font-size: $font-size-base;
        margin-top: -7px;
        position: absolute;
        right: 10px;
        top: 50%;
        vertical-align: middle;
    }

    .open &{
        border-radius: $border-radius-base $border-radius-base 0 0;
        border-bottom-color: transparent;
    }
}

.input-lg{
    height: 55px;
    padding: $padding-large-vertical $padding-large-horizontal;
}

.has-error{
    .form-control-feedback{
        color: $danger-color;
    }
}
.has-success{
    .form-control-feedback{
        color: $success-color
    }
}


.input-group-addon {
    background-color: $white-color;
    border: 1px solid $light-gray;
    border-radius: $border-radius-base;

    .has-success &,
    .has-error &{
        background-color: $white-color;
        border: 1px solid $light-gray;
    }
    .has-error .form-control:focus + &{
        border-color: $danger-color;
        color: $danger-color;
    }
    .has-success .form-control:focus + &{
        border-color: $success-color;
        color: $success-color;
    }
    .form-control:focus + &,
    .form-control:focus ~ &{
        background-color: $white-color;
        border-color: $dark-gray;
    }
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-right: 0 none;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
    border-left: 0 none;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: $smoke-bg;
    color: $default-color;
    cursor: not-allowed;
}

.input-group-btn .btn{
    border-width: $border-thin;
    padding: $padding-round-vertical $padding-base-horizontal;
}
.input-group-btn .btn-default:not(.btn-fill){
    border-color: $medium-gray;
}

.input-group-btn:last-child > .btn{
    margin-left: 0;
}

.input-group-focus .input-group-addon{
    border-color: $dark-gray;
}

.card .form-label{

}

/* TRACKS */
.tracksHeader{
  margin: 0px;
  margin-top: 2em;
  font-style: italic;
  border-bottom: 2px solid $musician-bright-color;
  p{
    font-weight: 500;
  }
}

.trackTable{
  .row,
  .col{
    margin: 0px;
  }
  p{
    margin: 0;
  }
}
.trackToggle{
  background-color: $lighter-gray;
  padding: 0.5em 0px;
  border: 1px solid $light-gray;
  border-top:none;
}

div.expanded ~ .trackToggle{
  button.btn-icon{
    transform: rotate(180deg);
  }
}
.trackInput{
  margin: 0px;
  padding: 1em;
  border: 1px solid $light-gray;
  border-top:none;
}

.card {
  .custom-file-label{
    overflow: hidden;
    line-height: 2em;
    font-size: 1rem;
    text-transform: none;
    padding: 0.2em 6em 0.2em 10px;
    color: inherit;
    border-color: rgb(204, 204, 204);
  }
  .placeholder .custom-file-label{
    color: hsl(0,0%,50%);
  }
  input {
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
  }
}


.addTrack{
  text-align: center;
  margin-top: 1em;
}

/* VOUCHER */
.voucher{
  text-align:center;
  input{
    width: 100%;
    display: inline;
    margin-bottom: 1em;
  }
}
@media (min-width: 576px) {
  .voucher{
    text-align: left;
    input{
      width: auto;
      max-width: calc(100% - 200px);
      margin-right: 2em;
    }
  }
}


/* WaveSurfer */

handle.wavesurfer-handle{
  width: 2px!important;
  background-color: $musician-bright-color !important;
}
div.waveReview{
  .waveform {
  width: calc(100% - 4em);
  display: inline-block;
  vertical-align: middle;
  }
  .btn-playpause {
    vertical-align: middle;
    padding-right: 0.4em;
    width: 2.1em;
    span{
      font-size: 30px;
    }
  }
  div.controls {
    .smallInputs {
      width: 9em;
      display: inline-flex;
      margin-right: 1em;
    }
    .btn-icon {
      background-color: transparent;
      color: $musician-bright-color;
    }
  }
}
.form-group, .trackSelectors > div, .input-group > div{
  .form-label,.form-check-label{
    text-transform: uppercase;
    color: #555;
    font-size: 14px;
  }
}

/* REVIEWs */

.slidersReview{
  padding: 0 10%;
  @include input-range-styled;
}

.trackSelectors{
  & > div {
    margin-bottom: 1em;
  }
}

.waveform{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -50px;
  margin-right: -50px;
  padding-left: 50px;
  padding-right: 50px;
}
.waveReview{
  & > button:hover{
    color: $musician-bright-color;
  }
}
.trackTable{
  border-top: 2px solid $musician-bright-color;
}
.TrackNumLabel{
  position: absolute;
  color:white;
  top: 10px;
  left: -35px;
  font-weight: bold;
  width: 25px;
  background: $musician-bright-color;
  text-align: center;
  border-radius: 50%;
}

.TrackSolo{
  position: absolute;
  color:white;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -35px;
  font-weight: bold;
  width: 25px;
  background: $medium-gray;
  text-align: center;
  border-radius: 50%;
}
.TrackMute{
  position: absolute;
  color:white;
  bottom: 10px;
  left: -35px;
  font-weight: bold;
  width: 25px;
  background: $medium-gray;
  text-align: center;
  border-radius: 50%;
}
.TrackButton:hover{
    border: 1px solid $musician-bright-color;
}
.TrackButton.on{
    background: $musician-sidebar-left;
}
.unableToUp{
  background-color: $lighter-gray;
  padding: 2px 15px;
  opacity: 0.6;
  border-radius: 12px;
}
