.formError{
  display: none;
}

.sili-validated,.was-validated{
  .formError{
    color: red;
    text-decoration: underline;
    display: inline;
  }
}

/* Valid/Invalid react-select styling to match others */
.was-validated {
  .SelectRequiredHelper:invalid+div>div:first-child{
    border-color: #dc3545;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(2.775em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);

  }
  .SelectRequiredHelper:valid+div>div:first-child{
    border-color: #28a745;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(2.775em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);

  }
}
