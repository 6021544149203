
.Musician {
  .wrapper{
    @include lr-gradient($musician-bg-left, $musician-bg-right);
  }
  .main-panel{
    @include lr-gradient($musician-bg-left, $musician-bg-right);

  }
  .navbar{
    @include lr-gradient($musician-bg-left, $musician-bg-right);

      border-bottom: 1px solid $musician-active;
  }

  .sidebar,
  body > .navbar-collapse{

      &:before{
          opacity: .33;
          @include lr-gradient($musician-sidebar-left, $musician-sidebar-right);
      }

      &[data-image]:after,
      &.has-image:after{
          opacity: 0;
      }

      &:after{
          @include lr-gradient($musician-sidebar-left, $musician-sidebar-right);
      }
  }

  .numberDot{
    background-color: $musician-bright-color;
    color: $white-color;
  }

  .numberDot.active{
    background-color: $white-color;
    color: $musician-bright-color;
  }

  .btn-primary{
    border: none;
    background-color: $musician-bright-color;
    color: $white-color;
  }


}


/* ENGINEER  --- */


.Engineer {
  .wrapper{
    @include lr-gradient($engineer-bg-left, $engineer-bg-right);
  }
  .main-panel{
    @include lr-gradient($engineer-bg-left, $engineer-bg-right);

  }
  .navbar{
    @include lr-gradient($engineer-bg-left, $engineer-bg-right);

      border-bottom: 1px solid $engineer-active;
  }

  .sidebar,
  body > .navbar-collapse{

      &:before{
          opacity: .33;
          @include lr-gradient($engineer-sidebar-left, $engineer-sidebar-right);
      }

      &[data-image]:after,
      &.has-image:after{
          opacity: 0;
      }

      &:after{
          @include lr-gradient($engineer-sidebar-left, $engineer-sidebar-right);
      }
  }

  .numberDot{
    background-color: $engineer-bright-color;
    color: $white-color;
  }

  .numberDot.active{
    background-color: $white-color;
    color: $engineer-bright-color;
  }

  .btn-primary{
    border: none;
    background-color: $engineer-bright-color;
    color: $white-color;
  }


}
