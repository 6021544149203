/*     General overwrite     */
body,
.wrapper{
    min-height: 100vh;
    position: relative;
    background-color: white;
}
a{
  color: $info-color;

  &:hover, &:focus{
     color: $info-states-color;
     text-decoration: none;
  }
}

a:focus, a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner{
    outline:0;
}
.ui-slider-handle:focus,
.navbar-toggler,
input:focus {
    outline : 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert{
    @include transition($general-transition-time, $transition-linear);
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn{
    @include transition($fast-transition-time, $transition-ease-in);
}

.btn{
    @include transition($ultra-fast-transition-time, $transition-ease-in);
}
.fa{
    /*width: 18px;*/
    text-align: center;
}
.margin-top{
    margin-top: 50px;
}

.wrapper{
    position: relative;
    top: 0;
    height: 100vh;
}

/* RATING */
.rating{
  text-align: center;
  .react-rater{
    font-size: 3em;
  }
  .btn{
    margin-bottom: 1em;
  }
}


/* REGION TRIANGLE*/
.triangle:after { content:''; display:block; width:0; height:0; position:absolute; border-left: 24px solid transparent; border-right: 24px solid transparent; border-bottom:24px solid $light-gray; left:calc(50% - 24px); bottom:-26px;}

.selectedRegionDescription{
  padding: 1em;
  margin-top: 1em;
  border: 1px solid $light-gray;
  border-radius: 5px;
}
.nav-item.reviewAccept{
  width: 50%;
}
