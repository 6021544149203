.modal-content{
    margin-top: 5em;
    border-radius: $border-radius-extreme;
    -webkit-box-shadow: 0px 20px 40px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 20px 40px rgba(0,0,0,0.75);
    box-shadow: 0px 20px 40px rgba(0,0,0,0.75);
    background-color: #FFFFFF;
    border: 1px solid #E4E2EB;
    margin-bottom: 30px;
    padding: 0px 1em;

    .modal-header{
      border-bottom: 0px;
    }
    .modal-footer{
      border-top: 0px;
    }
}
